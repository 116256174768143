<template>
	<div class="container">
		<div style="padding: 50px 0px 50px 130px;">
			<img src="../../assets/img/datibefor_head.png" style="display: block;width: 80%;" alt="">
		</div>
		<div class="container_bg-box">
			<div class="container_box">
				<div style="font-size: 18px;font-weight: bold;text-align: left;justify-content: flex-start;margin-left: 30px;">
					赛前悉知>>
				</div>
				<div class="container_box_text">
					<!-- <div class="number">1</div> -->
					<div class="title">本次科普知识竞答比赛分数满分100分，单选题（60题，1分1题）、多选题（10题，答对且答全2分，答对没答全1分）、判断题（20题，1分1题）</div>
				</div>
				<div class="container_box_text">
					<!-- <div class="number">2</div> -->
					<div class="title">知识领域：海洋自然环境、海洋生物、海洋文化、海洋展望、海洋探索、海洋保护等。</div>
				</div>
			</div>
		</div>
		<div class="container_btn">
			<div class="container_btn_box" v-if="flag">
				<div>请阅读以上内容 开始答题</div>
				<div>（{{ num }}秒倒计时）</div>
			</div>
			<div class="container_btn_box active" v-if="!flag" @click="handleClick">
				<div>我已阅读 开始答题</div>
			</div>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	export default {
		name: "notice",
		data() {
			return {
				flag: true,
				timer1: null,
				num: 10
			}
		},
		created() {
			this.countDown()
			localStorage.setItem('flag', '0')
		},

		methods: {
			countDown() {
				this.timer1 = setInterval(() => {
					this.num--
					if (this.num == 0) {
						clearInterval(this.timer1)
						this.flag = false
					}
				}, 1000)
			},
			handleClick() {
				// localStorage.setItem('flag', '1')
				// this.$router.push({name: 'daTi', query: { id: '1' }})
				this.$router.push({
					name: 'dati',
					query: {
						id: 1
					}
				})
			}
		},
		beforeDestroy() {
			clearInterval(this.timer1)

		}
	}
</script>

<style>
	body {
		background: url(../../assets/img/datibefor.png) no-repeat center;
		height: 100%;
		min-height: 100vh;
		background-size: cover;
	}
</style>
<style scoped lang="scss">
	.container {
		padding: 82px 30px 50px 30px;
		.container_bg-box {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			border-radius: 15px;
			.container_box {
				padding-top: 50px;
				width: 100%;
				height: 700px;
				box-sizing: border-box;
				background-color: #f9f8f8;
				padding-bottom: 66px;
				border-radius: 15px;
				&_title {
					width: 100%;
					text-align: center;
					font-size: 35px;

					img {
						width: 350px;
						vertical-align: bottom;
						margin-top: -80px;
					}
				}

				&_text {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-around;
					margin-top: 40px;

					.number {
						color: #fff;
						width: 45px;
						height: 45px;
						border-radius: 50%;
						background-color: #FFCA36;
						font-size: 35px;
						text-align: center;
						font-weight: 500;
						line-height: 45px;
						margin-top: 10px;
					}

					/* .number:after {
						content: '';
						height: 100px;
						width: 0px;
						border: 1px dashed #FFCA36;
						display: block;
						margin: 15px 0 0 20px;
					}

					.number3 {
						color: #fff;
						width: 45px;
						height: 45px;
						border-radius: 50%;
						background-color: #FFCA36;
						font-size: 35px;
						text-align: center;
						font-weight: 500;
						line-height: 45px;
					} */

					.title {
						font-family: '仿宋';
						width: 550px;
						font-size: 30px;
						font-weight: 600;
						letter-spacing: 4px;
						text-align: justify;
						line-height: 45px;
						text-indent: 40px;
					}
				}
			}
		}

		&_btn {
			width: 100%;
			text-align: center;
			padding: 52px 62px 0 62px;
			box-sizing: border-box;

			.container_btn_box {
				background-color: #58afff;
				border-radius: 10px;
				font-size: 29px;
				color: #fff;
				padding: 25px 0 25px 0;
				font-weight: 600;

				div {
					margin-bottom: 10px;
				}

				&:nth-child(3) {
					margin-bottom: 0;
				}
			}
		}

		.active {
			background: linear-gradient(to right, #2b66fd, #0006fe);
			color: #fff;
		}
	}
</style>
